<template>
  <header
    v-editable="blok"
    :class="{
      'container pt-[--blockSpacing]': !columnIndex,
      'pb-[--blockSpacing] lg:pb-0 lg:pt-0': requiresPadding,
    }"
  >
    <div
      class="flex flex-wrap items-center justify-between gap-6 lg:gap-8 lg:pl-0"
    >
      <div class="flex flex-col gap-4">
        <h2 class="text-2xl font-bold">
          {{ blok.title }}
        </h2>

        <p
          v-if="blok.subTitle"
          :class="{
            'text-gray-700': isLightTheme(pageSectionTheme),
          }"
        >
          {{ blok.subTitle }}
        </p>
      </div>

      <StoryblokComponent
        v-for="cta in blok.ctaButton"
        :key="cta._uid"
        :blok="cta"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
import type {
  BlockSectionTitleStoryblok,
  PageSectionStoryblok,
} from '@/types/storyblok'
import { isLightTheme } from '@/utils/theme-utils'

const props = defineProps<{
  blok: BlockSectionTitleStoryblok
  pageSectionTheme: PageSectionStoryblok['theme']
  columnIndex?: number
}>()

const requiresPadding = computed(
  () => !props.columnIndex && props.pageSectionTheme !== 'white',
)
</script>
